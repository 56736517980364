import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AirtimeState, Order, VoucherRouting } from "@/store/airtime/types";
import { VoucherApi } from "@/api/voucher-api";

export const actions: ActionTree<AirtimeState, RootState> = {
	async fetchAirtimeVouchers({ commit, state }) {
		try {
			commit("loading");
			// clean order vouchers list
			state.orders = [];
			const response = await VoucherApi.fetchAirtimeVouchers();
			commit("setOrders", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async fetchAirtimeHealth({ commit, state }) {
		try {
			commit("loading");
			// clean order vouchers list
			state.orders = [];
			const response = await VoucherApi.fetchAirtimeHealth();
			commit("setHealth", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async fetchAirtimeOrder({ commit }, orderId: number) {
		try {
			commit("loading");
			const response = await VoucherApi.fetchAirtimeOrder(orderId);
			commit("setOrder", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async fetchAirtimeNetworks({ commit }) {
		try {
			commit("loading");
			const response = await VoucherApi.fetchAirtimeNetworks();
			commit("setNetworks", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async fetchAirtimeQR({ commit, dispatch }, data: any) {
		try {
			commit("loading");
			const response = await VoucherApi.fetchAirtimeQR(data);
			commit("setBoxQR", "");
			commit("setBoxQR", response.data.qrCodeUrl);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("error");
		}
	},

	async fetchAirtimeVendors({ commit }) {
		try {
			commit("loading");
			const response = await VoucherApi.fetchAirtimeVendors();
			commit("setVendors", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async fetchAirtimeRouting({ commit }) {
		try {
			commit("loading");
			const response = await VoucherApi.fetchAirtimeRouting();
			commit("setRouting", response.data);
		} catch (error) {
			commit("error");
		}
	},

	async updateAirtimeRouting({ commit, dispatch }, payload: VoucherRouting) {
		try {
			commit("loading");
			await VoucherApi.updateAirtimeRouting(payload);
			dispatch("fetchAirtimeRouting");
		} catch (error) {
			commit("error");
		}
	},

	async changeOrderState({ commit, dispatch }, item: Order) {
		try {
			commit("loading");
			item.active = item.active === 1 ? 0 : 1;
			await VoucherApi.changeOrderState(item);
			dispatch("fetchAirtimeVouchers");
			dispatch("notifications/success", `Order ${item.active ? "activated" : "deactivated"}.`, { root: true });
		} catch (error) {
			commit("error");
		}
	},

	async fetchUssdMenu({ commit, dispatch }) {
		try {
			const request = await VoucherApi.fetchUssdMenu();
			commit("setUssdMenu", request.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateUssdMenu({ dispatch }, data) {
		try {
			const request = await VoucherApi.updateUssdMenu(data.menuId, data.active);
			dispatch("fetchUssdMenu");
			dispatch("notifications/success", `USSD Menu #${data.menuId} updated.`, { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	}
};
