import { utils } from "./http-common";
import Axios from "axios";
import { Order, VoucherRouting } from "@/store/airtime/types";

export class VoucherApi {
	static async fetchAirtimeVouchers() {
		return Axios.get(utils.generateUrl(`api/admin/Orders`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeHealth() {
		return Axios.get(utils.generateUrl(`api/Resource/health`, process.env.VUE_APP_VOUCHER_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeOrder(orderId: number) {
		return Axios.get(utils.generateUrl(`api/admin/Orders/${orderId}`, process.env.VUE_APP_VOUCHER_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeRouting() {
		return Axios.get(utils.generateUrl(`api/Routing`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeNetworks() {
		return Axios.get(utils.generateUrl(`api/Type/Networks`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeQR(data: any) {
		return Axios.get(utils.generateUrl(`api/admin/Boxes/${data.networkId}/${data.value}/${data.boxNumber}`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async fetchAirtimeVendors() {
		return Axios.get(utils.generateUrl(`api/Type/Vendors`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async updateAirtimeRouting(item: VoucherRouting) {
		return Axios.put(
			utils.generateUrl(`api/Routing/${item.id}`, process.env.VUE_APP_VOUCHER_URL),
			{
				vendorId: item.vendorId,
				blocked: item.blocked,
				blockedMessage: item.blockedMessage
			},
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async changeOrderState(item: Order) {
		return Axios.put(
			utils.generateUrl(`api/admin/Orders/${item.id}`, process.env.VUE_APP_VOUCHER_URL),
			{
				...item
			},
			{
				headers: await utils.generateHeader()
			}
		);
	}

	// USSD
	static async fetchUssdMenu() {
		return Axios.get(utils.generateUrl(`api/UssdMenu`, process.env.VUE_APP_VOUCHER_URL), {
			params: {},
			headers: await utils.generateHeader()
		});
	}

	static async updateUssdMenu(menuId: number, active: boolean) {
		return Axios.put(utils.generateUrl(`api/UssdMenu/${menuId}/${active}`, process.env.VUE_APP_VOUCHER_URL), null, {
			headers: await utils.generateHeader()
		});
	}
}
