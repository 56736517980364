<template>
	<S2SForm title="Airtime">
		<v-layout justify-end>
			<v-flex xs12 md6>
				<v-text-field label="Filter" v-model="searchVal" clearable></v-text-field>
			</v-flex>
		</v-layout>
		<v-data-table
			ref="dataTable"
			:headers="headers"
			:items="orders"
			:loading="status.loading"
			:options.sync="pagination"
			:search="searchVal"
			class="elevation-1"
		>
			<template v-slot:item.createDate="{ item }">
				<span>
					{{ formatDate(item.createDate) }}
				</span>
			</template>
			<template v-slot:item.active="{ item }">
				<v-btn icon @click="changeOrderState(item)" :disabled="status.loading">
					<v-icon color="accent" v-if="item.active" medium>mdi-checkbox-marked</v-icon>
					<v-icon v-else-if="!item.active" medium>mdi-close-box</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { airtime, state } from "@/store/airtime";
import { Status } from "@/store/utils";
import { Order } from "@/store/airtime/types";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "AirtimeOverview",

	computed: {
		orders: function() {
			return this.$store.state.airtime.orders;
		},
		selectedOrder: function() {
			return this.$store.state.airtime.selectedOrder;
		},
		status: function() {
			return this.$store.state.airtime.status;
		}
	},

	data: function() {
		return {
			headers: [
				{
					text: "Created Date",
					value: "createDate"
				},
				{
					text: "Order Request", // This is an id created by OrderCloud
					value: "orderRequest",
					sortable: false
				},
				{
					text: "Organistaion ID",
					value: "orgId",
					sortable: false
				},
				{
					text: "Username",
					value: "username"
				},
				{
					text: "Active",
					value: "active",
					align: "center"
				}
			],

			pagination: {
				page: 1,
				itemsPerPage: 10 // -1 for All
			},

			searchVal: "",
			dialog: false
		};
	},

	mounted: function() {
		this.$store.dispatch("airtime/fetchAirtimeVouchers");
		this.$store.dispatch("airtime/fetchAirtimeHealth");
	},

	methods: {
		viewOrder(orderId) {
			this.$store.dispatch("airtime/fetchAirtimeOrder", orderId);
			this.dialog = true;
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		changeOrderState(item) {
			this.$store.dispatch("airtime/changeOrderState", item);
		}
	}
});
</script>

<style scoped></style>
