import { Module } from "vuex";
import { getters } from "@/store/airtime/getters";
import { actions } from "@/store/airtime/actions";
import { mutations } from "@/store/airtime/mutations";
import { RootState } from "@/store/types";
import { AirtimeState } from "@/store/airtime/types";
import { statusFactory } from "../utils";

export const state: AirtimeState = {
	orders: [],
	airtimeRouting: [],
	vendors: [],
	selectedOrder: {
		id: -1,
		username: "",
		orgId: -1,
		orderRequest: -1,
		createDate: "",
		customerId: -1,
		active: -1
	},
	health: false,
	status: statusFactory(),
	networks: [],
	boxQR: "",
	ussdMenu: null
};

const namespaced: boolean = true;

export const airtime: Module<AirtimeState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
