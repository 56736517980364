import { AirtimeState } from "@/store/airtime/types";
import { MutationTree } from "vuex";

export const mutations: MutationTree<AirtimeState> = {
	setOrders(state, payload) {
		state.orders = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setHealth(state, payload) {
		state.health = payload.database;
	},

	setOrder(state, payload) {
		state.selectedOrder = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setRouting(state, payload) {
		state.airtimeRouting = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setVendors(state, payload) {
		state.vendors = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setNetworks(state, payload) {
		state.networks = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setBoxQR(state, payload) {
		state.boxQR = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},

	setUssdMenu(state, payload) {
		state.ussdMenu = payload;
	},

	loading(state) {
		state.status.error = false;
		state.status.loading = true;
		state.status.success = false;
	},
	success(state) {
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},
	error(state) {
		state.status.error = true;
		state.status.loading = false;
		state.status.success = false;
	}
};
